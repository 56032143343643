import { graphql, useStaticQuery } from "gatsby";
import React, { FunctionComponent, useEffect, useState } from "react";
import { VideoNode } from "../../../../types";
import Headline from "../../Headline";
import Link from "../../Link";
import Translation, { useI18next, useTranslation } from "../../Translation";
import Video from "../../Video";
import Thumbnail from "./Videos/Thumbnail";

const VideosAboutHepB: FunctionComponent = () => {
  const { t } = useTranslation();
  const data = useStaticQuery(graphql`
    query {
      whatIsHepBThumb: file(base: { eq: "what-is-hep-b-thumb.jpg" }) {
        ...SmallImage
      }
      whatIsHepBPoster: file(base: { eq: "what-is-hep-b.png" }) {
        publicURL
        ...SmallImage
      }
      whatIsHepB: allFile(filter: { base: { eq: "What_is_hep_B.mp4" } }) {
        nodes {
          ...VideoQuery
        }
      }

      amIAtRiskThumb: file(base: { eq: "am-i-at-risk-thumb.jpg" }) {
        ...SmallImage
      }
      amIAtRiskPoster: file(base: { eq: "am-i-at-risk.png" }) {
        publicURL
        ...SmallImage
      }
      amIAtRisk: allFile(filter: { base: { eq: "Am_I_at_risk.mp4" } }) {
        nodes {
          ...VideoQuery
        }
      }

      managingHepBThumb: file(base: { eq: "managing-hep-b-thumb.jpg" }) {
        ...SmallImage
      }
      managingHepBPoster: file(base: { eq: "managing-hep-b.png" }) {
        publicURL
        ...SmallImage
      }
      managingHepB: allFile(filter: { base: { eq: "Managing_hep_B.mp4" } }) {
        nodes {
          ...VideoQuery
        }
      }

      regularCheckupsThumb: file(base: { eq: "regular-checkups-thumb.jpg" }) {
        ...SmallImage
      }
      regularCheckupsPoster: file(base: { eq: "regular-checkups.png" }) {
        publicURL
        ...SmallImage
      }
      regularCheckups: allFile(
        filter: { base: { eq: "Regular_checkups.mp4" } }
      ) {
        nodes {
          ...VideoQuery
        }
      }

      whatShouldIKnowThumb: file(base: { eq: "what-should-i-know-thumb.jpg" }) {
        ...SmallImage
      }
      whatShouldIKnowPoster: file(base: { eq: "what-should-i-know.png" }) {
        publicURL
        ...SmallImage
      }
      whatShouldIKnow: allFile(
        filter: { base: { eq: "What_should_I_know.mp4" } }
      ) {
        nodes {
          ...VideoQuery
        }
      }

      arrow: file(base: { eq: "right.png" }) {
        publicURL
      }
      download: file(base: { eq: "download.svg" }) {
        publicURL
      }
      email: file(base: { eq: "email.png" }) {
        publicURL
      }
      facebook: file(base: { eq: "facebook.png" }) {
        publicURL
      }
      twitter: file(base: { eq: "twitter.png" }) {
        publicURL
      }
    }
  `);

  const [selection, setSelection] = useState("whatIsHepB");
  const [shareLink, setShareLink] = useState("https://www.hepb.com");

  const changeVideo = (name: string, e: MouseEvent) => {
    e.preventDefault();
    setSelection(name);
  };

  const currentPoster = () => {
    return data[selection + "Poster"].publicURL;
  };
  const currentVideos = (): { nodes: VideoNode[] } => {
    return data[selection];
  };
  const { language } = useI18next();
  const downloadURL = () => {
    const videos = currentVideos();
    const first = videos.nodes[0];

    const currentLanguageVideo =
      videos.nodes.find((node) => node.relativePath.match("^" + language)) ||
      first;
    if (currentLanguageVideo !== undefined) {
      return currentLanguageVideo.publicURL;
    }
    return "";
  };

  const caption = () => {
    switch (selection) {
      case "amIAtRisk":
        return <Translation id="find-about-risk" />;
      case "managingHepB":
        return <Translation id="learn-managing" />;
      case "regularCheckups":
        return <Translation id="learn-about-checkups" />;
      case "whatShouldIKnow":
        return <Translation id="find-facts" />;
      case "whatIsHepB":
      default:
        return <Translation id="find-info-hepb" />;
    }
  };

  useEffect(() => {
    setShareLink(
      "https://www.hepb.com" +
      window.location.pathname +
      encodeURIComponent(window.location.hash.toString())
    );
  }, []);
  const emailLink = () => {
    return "mailto:?body=" + shareLink;
  };
  const facebookLink = () => {
    return (
      "https://www.facebook.com/sharer/sharer.php?u=" +
      shareLink +
      "&redirect_uri=https://www.facebook.com"
    );
  };
  const tweetLink = () => {
    return (
      "https://twitter.com/intent/tweet?text=" +
      shareLink +
      "&amp;source=webclient"
    );
  };

  return (
    <div id="videos" className="chapter">
      <Headline>
        <h2>
          <Translation id="videos-about-hepb" />
        </h2>
      </Headline>
      <div className="video-selector p-8">
        <div className="flex flex-col desktop:flex-row desktop:items-stretch">
          <div className="w-full desktop:w-2/3 border border-black">
            <Video videos={currentVideos()} poster={currentPoster()} />
            <div className="text-xs text-center text-green bg-green-mint py-2 border-b border-grey">
              {caption()}
              <img
                src={data.arrow.publicURL}
                className="ml-4 w-4 inline-block"
              />
            </div>
            <div className="flex w-full">
              <div className="bg-grey-light py-2 w-1/2 text-center text-grey  text-xs uppercase">
                <Link href={downloadURL()} download>
                  <Translation id="download" />
                  <img
                    src={data.download.publicURL}
                    className="ml-4 inline-block"
                  />
                </Link>
              </div>
              <div className="bg-grey-light w-1/2 border-l border-green">
                <a href={emailLink()}>
                  <img
                    src={data.email.publicURL}
                    className="ml-4 inline-block"
                  />
                </a>
                <a href={facebookLink()}>
                  <img
                    src={data.facebook.publicURL}
                    className="ml-4 inline-block"
                  />
                </a>
                <a href={tweetLink()}>
                  <img
                    src={data.twitter.publicURL}
                    className="ml-4 inline-block"
                  />
                </a>
              </div>
            </div>
          </div>
          <div className="w-full desktop:w-1/3 flex flex-col justify-around border-t border-b border-grey-medium">
            <Thumbnail
              id="what-is-hep-b"
              title={t("what-is-hep-b")}
              image={data.whatIsHepBThumb}
              onClick={(e) => changeVideo("whatIsHepB", e)}
              active={selection === "whatIsHepB"}
            />
            <Thumbnail
              id="am-i-at-risk"
              title={t("am-i-at-risk")}
              image={data.amIAtRiskThumb}
              onClick={(e) => changeVideo("amIAtRisk", e)}
              active={selection === "amIAtRisk"}
            />
            <Thumbnail
              id="managing-hep-b"
              title={t("managing-hep-b")}
              image={data.managingHepBThumb}
              onClick={(e) => changeVideo("managingHepB", e)}
              active={selection === "managingHepB"}
            />
            <Thumbnail
              id="regular-checkups"
              title={t("regular-checkups")}
              image={data.regularCheckupsThumb}
              onClick={(e) => changeVideo("regularCheckups", e)}
              active={selection === "regularCheckups"}
            />
            <Thumbnail
              id="what-should-i-know"
              title={t("what-should-i-know")}
              image={data.whatShouldIKnowThumb}
              onClick={(e) => changeVideo("whatShouldIKnow", e)}
              active={selection === "whatShouldIKnow"}
              last
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default VideosAboutHepB;
