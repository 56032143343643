import React, { FunctionComponent } from "react";
import { WildcardRouteProps } from "../../types";
import Layout from "../components/Layout/Layout";
import Resources from "../components/Markup/Resources/Resources";
import SEO from "../components/seo";

const childRoutes = [
  "helpful-resources",
  "videos",
  "brochure",
  "ABCs-of-hepatitis",
];
const ResourcesPage: FunctionComponent<WildcardRouteProps> = ({
  "*": slug,
}) => {
  return (
    <Layout slug={slug} childRoutes={childRoutes}>
      <SEO name={"resources"} baseName="resources" />
      <Resources />
    </Layout>
  );
};
export default ResourcesPage;
