import React, { FunctionComponent } from "react";
import Headline from "../../Headline";
import Translation from "../../Translation";

const HelpfulResources: FunctionComponent = () => {
  return (
    <div id="helpful-resources" className="chapter">
      <Headline>
        <h1>
          <Translation id="helpful-resources" />
        </h1>
      </Headline>
      <p className="pt-4 px-4 laptop:pt-12 laptop:pr-4 pb-8 laptop:pl-8">
        <Translation id="whether-concerned-or-diagnosed" />
      </p>
    </div>
  );
};

export default HelpfulResources;
