import { graphql, useStaticQuery } from "gatsby";
import React, { FunctionComponent } from "react";
import Headline from "../../Headline";
import Link from "../../Link";
import Image from "../../Image";
import TranslatedImage from "../../TranslatedImage";
import Translation, { useTranslation } from "../../Translation";

const Brochure: FunctionComponent = () => {
  const { t } = useTranslation();
  const data = useStaticQuery(graphql`
    query {
      brochureCover: allFile(filter: { base: { eq: "pdf.png" } }) {
        nodes {
          relativePath
          ...MobileImage
        }
      }
      brochureCoverMobile: file(base: { eq: "brochure-cover.png" } ) {
        ...MobileImage
      }
      download: file(base: { eq: "download.svg" }) {
        publicURL
      }
      brochures: allFile(filter: { base: { eq: "Brochure.pdf"}}) {
        nodes {
          relativePath
          publicURL
        }
      }
    }
  `);

  function languageLink(label: string, language: string, className: string = "") {
    const brochure = data.brochures.nodes.find(b => b.relativePath.match(language));
    return (
      <li key={language} lang={language} className={["uppercase text-green my-2 text-sm", className].join(" ")}>
        <Link download href={brochure.publicURL}>
          <img src={data.download.publicURL} className="w-6 inline-block mr-2" />
          {label}
        </Link>
      </li>
    );
  }

  return (
    <div id="brochure" className="chapter">
      <Headline>
        <h2>
          <Translation id="hepb-brochure" />
        </h2>
      </Headline>
      <div className="p-8">
        <h2>
          <Translation id="understanding-hep-b" />
        </h2>
        <p>
          <Translation id="helpful-brochure-downloaded" />
        </p>
        <div className="flex flex-col laptop:flex-row justify-center items-center my-6">
          <div className="w-full laptop:w-1/3 ">
            <TranslatedImage
              images={data.brochureCover}
              alt={t("helpful-brochure-alt")}
              width={250}
              className="hidden laptop:block"
            />
            <Image fluid={data.brochureCoverMobile.childImageSharp.fluid} width={231} className="block laptop:hidden mx-auto" />
          </div>
          <div className="flex flex-col w-full laptop:flex-row laptop:w-1/2">
            <ul className="w-full laptop:w-1/2">
              {languageLink("ENGLISH", "en")}
              {languageLink("繁體中文", "tch")}
              {languageLink("한국어", "kor")}
              {languageLink("ខ្មែរ", "khm")}
              {languageLink("SOOMAALI", "som")}
              {languageLink("Kreyòl ayisyen", "hat", "hidden laptop:block")}
            </ul>
            <ul className="w-full laptop:w-1/2">
              {languageLink("简体中文", "sch")}
              {languageLink("TIẾNG VIỆT", "vie")}
              {languageLink("မြန်မာ", "bur")}
              {languageLink("HMOOB", "hmn")}
              {languageLink("ESPAÑOL", "spa")}
              {languageLink("Kreyòl ayisyen", "hat", "block laptop:hidden")}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Brochure;
