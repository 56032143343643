import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Translation from "../../Translation";
import Article from "../../Article";
import HelpfulResources from "./HelpfulResources";
import VideosAboutHepB from "./VideosAboutHepB";
import Brochure from "./Brochure";
import Abcs from "./Abcs";

const Resources = () => {
  // const data = useStaticQuery(
  //   graphql`
  //     query {
  //     }
  //   `
  // )

  return (
    <section id="resources" className="main-section">
      <Article>
        <HelpfulResources />
        <VideosAboutHepB />
        <Brochure />
        <Abcs />
      </Article>
    </section>
  );
};
export default Resources;
