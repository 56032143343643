import React, { FunctionComponent } from "react";
import Image from "../../../Image";

type ThumbnailProps = {
  id: string;
  image: any;
  title: string;
  onClick: () => void;
  active: boolean;
  last?: boolean;
};
const Thumbnail: FunctionComponent<ThumbnailProps> = ({
  image,
  title,
  id,
  onClick,
  active,
  last,
}) => {
  return (
    <a
      href={"#" + id}
      onClick={onClick}
      className={[
        "pl-2",
        "py-3",
        "flex",
        "items-center",
        "justify-self-stretch",
        !last && "border-b border-grey-medium",
        active ? "bg-grey-light" : "bg-white",
      ].join(" ")}
    >
      <div className="w-24 h-16 flex-shrink-0 flex-grow-0">
        <Image fluid={image.childImageSharp.fluid} alt={title} />
      </div>
      <div className={["pl-8", active ? "text-red" : "text-green"].join(" ")}>
        {title}
      </div>
    </a>
  );
};

export default Thumbnail;
