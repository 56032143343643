import { graphql } from "gatsby";
import React, { FunctionComponent, useEffect, useRef, useState } from "react";
import { VideoNode, VideoProps } from "../../types";
import { formatTime } from "../helpers/tracker";
import { useI18next } from "./Translation";

const Video: FunctionComponent<VideoProps> = ({ videos, poster }) => {
  const videoRef = useRef<HTMLVideoElement>();
  if (!videos) {
    return <div className="video"></div>;
  }
  const { language } = useI18next();
  const first = videos.nodes[0];
  const currentVideo =
    videos.nodes.find((node) => node.relativePath.match("^" + language)) ||
    first;

  const DURATION_EVENT_INTERVAL = 15;//seconds
  const PROGRESS_EVENT_TRIGGERS = [0.05, 0.1, 0.25, 0.5, 0.75, 0.95]

  let timer = 0;
  const [duration, setDuration] = useState(0);
  const [lastTrigger, setLastTrigger] = useState(0);
  const TICK_INTERVAL = 1000;

  useEffect(() => {
    videoRef.current?.load();
    videoRef.current?.addEventListener("play", onPlay);
    videoRef.current?.addEventListener("pause", onPause);

    setDuration(0);
    setLastTrigger(0);

    return () => {
      videoRef.current?.removeEventListener("play", onPlay);
      videoRef.current?.removeEventListener("pause", onPause);
      if (timer) {
        trackStop();
      }
      window.clearInterval(timer);
    }

  }, [videos]);


  useEffect(() => {
    checkDuration();
    checkTriggers();
  }, [duration]);

  const checkDuration = () => {
    if (duration > 1 && duration % DURATION_EVENT_INTERVAL === 0) {
      trackDuration();
    }
  }
  function checkTriggers() {
    if (videoRef.current) {
      const { currentTime, duration } = videoRef.current;
      if (duration) {
        const currentPct = currentTime / duration;
        PROGRESS_EVENT_TRIGGERS.reduce((cur, trigger) => {
          if (cur >= trigger) {
            if (trigger > lastTrigger) {
              trackTrigger(trigger);
              setLastTrigger(trigger);
            }
          }
          return cur;
        }, currentPct)
      }
    }

  }


  const onPlay = () => {
    trackStart();
    timer = window.setInterval(tick, TICK_INTERVAL);
  }
  const tick = () => {
    setDuration(duration => duration + 1);
  }

  const onPause = () => {
    trackStop();
    if (timer) {
      window.clearInterval(timer);
      timer = 0;
    }
  }

  const trackDuration = () => {
    track(`Viewed for ${formatTime(duration)}`);
  }
  const trackTrigger = (percent: number) => {
    track(`Viewed ${Math.floor(percent * 100)}%`)
  }

  const trackStart = () => {
    track("Start");
  }
  const trackStop = () => {
    track("Stop");
  }

  const track = (eventAction: string) => {
    const data = {
      eventCategory: "Videos",
      eventAction,
      eventLabel: videoLabel(currentVideo),
      subject: "Disease Information",
      audience: "Patient",
      nonInteraction: false,
      language
    }
    window.dataLayer.push(data);

  }

  const videoLabel = (video: VideoNode) => {
    const { relativePath } = video;
    return "Explainer Video: " + relativePath.replace(/.*\//, "")//remove lang
      .replace(/\.mp4/, "")//remove extension
      .replace(/_/g, " "); //add spaces

  }


  return (
    <video
      className="video"
      controls
      poster={poster}
      preload="metadata"
      ref={videoRef}
    >
      <source src={currentVideo.publicURL} type="video/mp4" />
      Video Placeholder
    </video>
  );
};

export default Video;

export const VideoQuery = graphql`
  fragment VideoQuery on File {
    relativePath
    publicURL
  }
`;
