import { graphql, useStaticQuery } from "gatsby";
import React, { FunctionComponent } from "react";
import Headline from "../../Headline";
import HorizontalRule from "../../HorizontalRule";
import Translation, { useI18next, useTranslation } from "../../Translation";
import Link from "../../Link";
import Button from "../../Button";
import TranslatedImage from "../../TranslatedImage";

const Abcs: FunctionComponent = () => {
  const { t } = useTranslation();
  const data = useStaticQuery(
    graphql`
      query {
        livers: allFile(filter: { base: { eq: "livers.png" } }) {
          nodes {
            relativePath
            ...LiverImage
          }
        }
        mobileLivers: allFile(filter: { base: { eq: "mobile-livers.png" } }) {
          nodes {
            relativePath
            ...LiverImage
          }
        }
        abcs: allFile(filter: { base: { eq: "ABCs-of-Hepatitis.pdf" } }) {
          nodes { 
            relativePath
            publicURL
          }
        }
      }
    `
  );

  const { language } = useI18next();
  const firstBrochure = data.abcs.nodes[0];
  const currentBrochure = data.abcs.nodes.find((node) => node.relativePath.match("^" + language)) || firstBrochure;

  return (
    <div id="ABCs-of-hepatitis" className="chapter">
      <Headline>
        <h2>
          <Translation id="abcs-of-hepatitis" />
        </h2>
      </Headline>
      <div className="px-8 py-8">
        <div>
          <h2>
            <Translation id="hep-a" />
          </h2>
          <h3 className="text-green">
            <Translation id="hep-a-can-be-spread" />
          </h3>
          <ul className="bullet">
            <li>
              <Translation id="eating-or-drinking" />
            </li>
            <li>
              <Translation id="being-in-close-contact" />
            </li>
            <li>
              <Translation id="having-sex-with-infected" />
            </li>
          </ul>
          <p>
            <Translation id="usually-fight-off" />
          </p>
          <p>
            <Translation id="no-specific-treatment" />
          </p>
        </div>
        <HorizontalRule />
        <div>
          <h2>
            <Translation id="hep-b" />
          </h2>
          <h3 className="text-green">
            <Translation id="hep-b-can-be-spread" />
          </h3>
          <ul className="bullet">
            <li>
              <Translation id="during-birth" />
            </li>
            <li>
              <Translation id="bodily-fluids" />
            </li>
            <li>
              <Translation id="having-sex-with-infected" />
            </li>
          </ul>
          <p>
            <Translation id="hep-b-cannot-spread" />
          </p>
          <p>
            <Translation id="can-be-short-term" />
          </p>
          <div className="laptop:w-4/5 border border-green rounded-2xl p-4 text-green">
            <div className="pb-4">
              <Translation id="can-be-chronic" />
            </div>
            <div className="">
              <TranslatedImage
                images={data.livers}
                alt={t("may-cause-inflammation")}
                className="hidden laptop:block"
              />
              <TranslatedImage
                images={data.mobileLivers}
                alt={t("may-cause-inflammation")}
                className="block laptop:hidden"
              />
            </div>
          </div>
          <p>
            <Translation id="sometimes-called-silent-killer" />
          </p>
          <p>
            <Translation id="chb-treatments-available" />
          </p>
        </div>
        <HorizontalRule />
        <div>
          <h2>
            <Translation id="hep-c" />
          </h2>
          <ul className="bullet">
            <li>
              <Translation id="hep-c-spreads" />
            </li>
            <li>
              <Translation id="not-contagious" />
            </li>
            <li>
              <Translation id="slow-progressing" />
            </li>
            <li>
              <Translation id="no-vaccine" />
            </li>
          </ul>
          <p>
            <Translation id="visit-1" />
            <Link
              href="https://www.hepchope.com"
              analytics="'External Links','Clicked','Hep C Hope','Interaction','Company Information'"
              className="font-bold underline"
              external
              skipModal
            >
              <Translation id="hepchope-com" />
            </Link>
            <Translation id="visit-2" />
            <a
              href={"tel://" + t("hepchope-phone-numeric")}
              className="underline"
            >
              <Translation id="hepchope-phone" />
            </a>{" "}
            (<Translation id="hepchope-phone-numeric" />)
            <Translation id="visit-3" />
          </p>
          <div className="mx-auto py-4 text-center">
            <Button download href={currentBrochure.publicURL} text={t("download-abcs")} size="large" color="green" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Abcs;
